(function(w, d) {
    var bulletins = {
        init: function () {
            w.bulletins_config.forEach(function(o) {
                var block = d.querySelector('#tncms-block-' + o.block_id),
                    allItems = block.querySelectorAll('.masonry-brick'),
                    grid = block.getElementsByClassName("masonry")[0],
                    rowHeight = parseInt(w.getComputedStyle(grid).getPropertyValue('grid-auto-rows')),
                    rowGap = parseInt(w.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
                    ua = window.navigator.userAgent,
                    edge = ua.indexOf('Edge/');
                // Check for support
                var supportsGrid = typeof CSS !== 'undefined'
                    && typeof CSS.supports !== 'undefined'
                    && CSS.supports('display', 'grid');
               // Size single item for grid
               function resizeGridItem(item) {
                   var rowSpan = Math.ceil((item.querySelector('.masonry-content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
                   item.style.gridRowEnd = "span "+rowSpan;
               }
               // Resizes all items in grid
               function resizeAllGridItems() {
                   for(var x = 0; x < allItems.length; x++){
                       var item = allItems[x];
                       item.addEventListener("click", modalOpen, false);
                       resizeGridItem(item);
                       // Add class for IE support
                       if (!supportsGrid) {
                           if ( item.innerHTML.includes('headline-only') ) {
                               item.classList.add('height-help');
                           }
                       }
                   }
               }
               // Updated overlay size
               function resizeOverlays() {
                   for(var j = 0; j < allItems.length; j++){
                       var item = allItems[j],
                           overlay = item.querySelector('.overlay'),
                           content = item.querySelector('.masonry-content');
                       overlay.style.height = content.getBoundingClientRect().height + "px";
                   }
               }
               // Open modal, update iframe src & title    
               function modalOpen(e) {
                    var modal = e.target.closest('.overlay'),
                        title = modal.getAttribute('data-title'),
                        attr = modal.getAttribute('href') + '?mode=bulletin&modal_doc=true',
                        close = block.querySelector('.close'),
                        iframe = block.querySelector('.bulletins-modal iframe'),
                        modalTitle = block.querySelector('#myModalLabel');
                    iframe.setAttribute("src", attr);
                    modalTitle.innerHTML = title;
                    close.addEventListener("click", modalClose, false)
                } 
                // Close modal, remove iframe src & title    
                function modalClose() {
                    var iframe = block.querySelector('.bulletins-modal iframe'),
                        modalTitle = block.querySelector('#myModalLabel');
                    iframe.setAttribute("src", '');
                    modalTitle.innerHTML = '';
                }  
                // Compensate for edge
                if (edge > 0){
                    block.querySelector('.masonry').classList.add('edge');
                } else {
                    block.querySelector('.masonry').classList.add('grid');
                }
                // Call grid and overlays resizes simultaionusly
                function gridResize() {
                    setTimeout(resizeAllGridItems, 100);
                    setTimeout(resizeOverlays, 200);
                }  
                // Observer callback
                function callback(e){
                  e.forEach(function(){
                      gridResize();
                  });
                }
                var options = {
                  root: null,
                  rootMargin: '0px 0px 600px',
                  threshold: [0, 0.2, 0.4, 0.6, 0.8, 1]
                }
                var observer = new IntersectionObserver(callback, options);     
                // Fire resizes
                observer.observe(block);
                block.querySelector('.masonry').classList.add('fade-in');
                w.addEventListener("resize", gridResize());
            })  
        }
    }  
    if (d.readyState == 'loading') {
        d.addEventListener('DOMContentLoaded', function() {
           bulletins.init();
        })
    }  else {
        bulletins.init();
    }
})(window, document);